import { createFeature, createReducer, on } from '@ngrx/store';
import { Profile } from '@swagger/models';

import { UserActions } from './user.actions';

interface State {
  error: unknown;
  loading: boolean;
  resetPasswordAvailabilityLoading: boolean;
  user: Profile | undefined;
}

const initialState: State = {
  error: undefined,
  loading: false,
  resetPasswordAvailabilityLoading: false,
  user: undefined,
};

const reducer = createReducer(
  initialState,
  on(
    UserActions.userLogin,
    UserActions.userLogout,
    UserActions.userUpdate,
    UserActions.restorePassword,
    UserActions.resetPassword,
    UserActions.loginWithDisposableToken,
    (state): State => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    UserActions.userLoginSuccess,
    UserActions.restorePasswordSuccess,
    UserActions.resetPasswordSuccess,
    UserActions.loginWithDisposableTokenSuccess,
    (state): State => ({
      ...state,
      error: undefined,
      loading: false,
    }),
  ),
  on(
    UserActions.userLoginFailure,
    UserActions.loadUserFailure,
    UserActions.userUpdateFailure,
    UserActions.restorePasswordFailure,
    UserActions.resetPasswordFailure,
    UserActions.loginWithDisposableTokenFailure,
    (state, { error }): State => ({
      ...state,
      error,
      loading: false,
    }),
  ),
  on(
    UserActions.loadUserSuccess,
    (state, { user }): State => ({
      ...state,
      user,
    }),
  ),
  on(
    UserActions.userUpdateSuccess,
    (state, { user }): State => ({
      ...state,
      error: undefined,
      loading: false,
      user,
    }),
  ),
  on(
    UserActions.resetPasswordAvailability,
    (state): State => ({
      ...state,
      resetPasswordAvailabilityLoading: true,
    }),
  ),
  on(
    UserActions.resetPasswordAvailabilitySuccess,
    (state): State => ({
      ...state,
      error: undefined,
      resetPasswordAvailabilityLoading: false,
    }),
  ),
  on(
    UserActions.resetPasswordAvailabilityFailure,
    (state, { error }): State => ({
      ...state,
      error,
      resetPasswordAvailabilityLoading: false,
    }),
  ),
);

export const userFeature = createFeature({
  name: 'user',
  reducer,
});
