import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  Profile,
  ProfileDisposableLogin,
  ProfileLogin,
  ProfileResetPassword,
  ProfileRestorePassword,
  ProfileUpdate,
  Token,
} from '@swagger/models';
import { ProfileService } from '@swagger/services/profile.service';

export const UserActions = createActionGroup({
  events: {
    'Load User': emptyProps(),
    'Load User Failure': props<{ error: unknown }>(),
    'Load User Success': props<{ user: Profile }>(),

    'Login With Disposable Token': props<{ payload: ProfileDisposableLogin }>(),
    'Login With Disposable Token Failure': props<{ error: unknown }>(),
    'Login With Disposable Token Success': props<{
      payload: Token;
    }>(),

    'Reset Password Availability': props<{
      token: ProfileService.ProfileResetPasswordListParams['token'];
    }>(),
    'Reset Password Availability Failure': props<{ error: unknown }>(),
    'Reset Password Availability Success': emptyProps(),

    // eslint-disable-next-line perfectionist/sort-objects
    'Reset Password': props<{ payload: ProfileResetPassword }>(),
    'Reset Password Failure': props<{ error: unknown }>(),
    'Reset Password Success': emptyProps(),

    'Restore Password': props<{ payload: ProfileRestorePassword }>(),
    'Restore Password Failure': props<{ error: unknown }>(),
    'Restore Password Success': emptyProps(),

    'User Login': props<{ payload: ProfileLogin }>(),
    'User Login Failure': props<{ error: unknown }>(),
    'User Login Success': props<{
      authToken: string;
      loginData: ProfileLogin;
    }>(),

    'User Logout': emptyProps(),

    'User Update': props<{ payload: ProfileUpdate }>(),
    'User Update Failure': props<{ error: unknown }>(),
    'User Update Success': props<{ user: ProfileUpdate }>(),
  },
  source: 'User',
});
