import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import {
  EventBusService,
  LocalStorageService,
  SessionStorageService,
} from '@expocab/shared/api';
import { ROUTE_TOKENS, STORAGE_KEYS } from '@expocab/shared/app-config';
import { NAVIGATOR, WINDOW } from '@ng-web-apis/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileService } from '@swagger/services/profile.service';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, exhaustMap, map, tap } from 'rxjs/operators';

import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
  private readonly ROUTE_TOKENS = ROUTE_TOKENS;
  private readonly STORAGE_KEYS = STORAGE_KEYS;

  checkResetPasswordAvailability$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetPasswordAvailability),
      exhaustMap(({ token }) =>
        this.profileService.profileResetPasswordList({ token }).pipe(
          map(() => UserActions.resetPasswordAvailabilitySuccess()),
          catchError((error: unknown) =>
            of(
              UserActions.resetPasswordAvailabilityFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while checking password reset availability',
              }),
            ),
          ),
        ),
      ),
    );
  });

  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUser),
      exhaustMap(() =>
        this.profileService.profileMeList({}).pipe(
          map((user) => UserActions.loadUserSuccess({ user })),
          catchError((error: unknown) =>
            of(
              UserActions.loadUserFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while loading user',
              }),
            ),
          ),
        ),
      ),
    );
  });

  loginWithDisposableToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loginWithDisposableToken),
      exhaustMap(({ payload }) =>
        this.profileService.profileDisposableLoginCreate(payload).pipe(
          map(({ auth_token }) =>
            UserActions.loginWithDisposableTokenSuccess({
              payload: { auth_token },
            }),
          ),
          catchError((error: unknown) =>
            of(
              UserActions.loginWithDisposableTokenFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while logging with disposable token',
              }),
            ),
          ),
        ),
      ),
    );
  });

  navigateAfterLoginWithDisposableLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.loginWithDisposableTokenSuccess),
        delay(3_000),
        tap(({ payload }) => {
          if (!payload.auth_token)
            throw new Error("Token is empty or isn't provided");

          this.localStorage.setItem(
            this.STORAGE_KEYS.AUTH_TOKEN,
            payload.auth_token,
          );
          this.router.navigate([`/${this.ROUTE_TOKENS.EXHIBITIONS}`]);
        }),
        catchError((error: unknown) => {
          // eslint-disable-next-line no-console
          console.error(error);
          return EMPTY;
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  navigateAfterPasswordReset$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.resetPasswordSuccess),
        tap(() =>
          this.router.navigate([
            `/${this.ROUTE_TOKENS.RESTORE_PASSWORD_SUCCESS}`,
          ]),
        ),
      );
    },
    { dispatch: false },
  );

  removeTokenAndNavigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.userLogout, UserActions.loadUserFailure),
        tap(() => {
          this.localStorage.removeItem(this.STORAGE_KEYS.AUTH_TOKEN);
          this.dialog.closeAll();
          if (
            'PasswordCredential' in this.windowRef &&
            this.navigatorRef.credentials.preventSilentAccess
          ) {
            this.navigatorRef.credentials.preventSilentAccess();
          }
          this.router.navigate(['/']);
        }),
      );
    },
    { dispatch: false },
  );

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetPassword),
      exhaustMap(({ payload }) =>
        this.profileService.profileResetPasswordCreate(payload).pipe(
          map(() => UserActions.resetPasswordSuccess()),
          catchError((error: unknown) =>
            of(
              UserActions.resetPasswordFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while resetting password',
              }),
            ),
          ),
        ),
      ),
    );
  });

  restorePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.restorePassword),
      exhaustMap(({ payload }) =>
        this.profileService.profileRestorePasswordCreate(payload).pipe(
          map(() => UserActions.restorePasswordSuccess()),
          catchError((error: unknown) =>
            of(
              UserActions.restorePasswordFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while restoring password',
              }),
            ),
          ),
        ),
      ),
    );
  });

  restorePasswordSuccessEvent = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.restorePasswordSuccess),
        tap(() => this.eventBus.pushEvent('restore-password')),
      );
    },
    { dispatch: false },
  );

  saveTokenAndNavigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.userLoginSuccess),
        tap(({ authToken, loginData }) => {
          if (!authToken) throw new Error("Token is empty or isn't provided");

          this.localStorage.setItem(this.STORAGE_KEYS.AUTH_TOKEN, authToken);
          this.sessionStorage.clear();

          if (
            'PasswordCredential' in this.windowRef &&
            typeof PublicKeyCredential !== 'undefined'
          ) {
            const credentials = new PasswordCredential({
              id: loginData.username,
              name: loginData.username,
              password: loginData.password,
            });

            this.navigatorRef.credentials
              .store(credentials)
              .then(() => {
                if (isDevMode())
                  // eslint-disable-next-line no-console
                  console.log(
                    "Credential stored in the user agent's credential manager",
                  );
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          }

          this.router.navigate([`/${this.ROUTE_TOKENS.EXHIBITIONS}`]);
        }),
        catchError((error: unknown) => {
          // eslint-disable-next-line no-console
          console.error(error);
          return EMPTY;
        }),
      );
    },
    { dispatch: false },
  );

  sendEventOnUserUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.userUpdateSuccess),
        tap(() => this.eventBus.pushEvent('user-profile-update')),
      );
    },
    { dispatch: false },
  );

  userLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userLogin),
      exhaustMap(({ payload }) =>
        this.profileService
          .profileLoginCreate({
            password: payload.password,
            username: payload.username,
          })
          .pipe(
            map(({ auth_token }) =>
              UserActions.userLoginSuccess({
                authToken: auth_token,
                loginData: payload,
              }),
            ),
            catchError((error: unknown) =>
              of(
                UserActions.userLoginFailure({
                  error:
                    (<HttpErrorResponse>error)?.error ??
                    'Error while user logging',
                }),
              ),
            ),
          ),
      ),
    );
  });

  userUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userUpdate),
      exhaustMap(({ payload }) =>
        this.profileService.profileMePartialUpdate(payload).pipe(
          map((user) => UserActions.userUpdateSuccess({ user })),
          catchError((error: unknown) =>
            of(
              UserActions.userUpdateFailure({
                error:
                  (<HttpErrorResponse>error)?.error ??
                  'Error while updating user',
              }),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    @Inject(NAVIGATOR) private readonly navigatorRef: Navigator,
    private actions$: Actions,
    private profileService: ProfileService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private dialog: Dialog,
    private eventBus: EventBusService,
  ) {}
}
